import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Recaptcha from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { maskJs } from 'mask-js';

// hooks and utils
import { useProductFlow } from 'hooks/useProductFlow';
import { useCart } from 'hooks/useCart';
import useSessionStorage from 'hooks/useSessionStorage';
import {
  getFormatDate,
  isBirthDateHigherOrEqualThan18Years,
  unformatDate,
  useLeadValidationSchema,
} from './utils';
import { sendDataToAnalytics } from 'utils/sendDataToAnalytics';
import { getErrorMessage, getErrorTitle } from 'utils/errors';
import { getAnalyticsGclid } from 'utils/getAnalyticsGclid';
import ReturnFirstCapital from 'utils/returnFirstCapital';
import { errorsMessage, formatValueBeforeSend } from 'utils/globalFunctions';
import * as validate from 'utils/inputValidate';
import { getProductV3CaptchaKey } from 'utils/getProductCaptchaKey';
import { getUtmMetadata } from 'utils/getUtmMetadata';

// services
import { createLead } from 'services/repositories/leads';

// components
import Steps from 'componentsV2/Steps';
import ErrorBox from 'componentsV2/ErrorBox';
import PhoneNumberInput from 'componentsV2/PhoneNumberInput';
import PoliticsAndTerms from 'componentsV2/PoliticsAndTerms';
import SecurityFooter from 'componentsV2/DS/SecurityFooter';
import {
  DsButton,
  DsTextField,
  DsCheckbox,
  DsTypography,
} from 'componentsV2/DS';

// constants
import { DATA_FORM, DATA_LEAD } from '../../../constants/';
import { CAPTCHA_TOKEN_STAGING } from 'config/captchaStaging';

// styles
import { ErrorBoxContainer, Form, TitleBox, Wrapper } from './styles';

// types
import { type LeadData } from 'types';
import { useRouterQuery } from 'hooks/useRouterQuery';

interface LeadScreenProps {
  contactValues?: {
    name: string;
    email: string;
    phone: string;
  };
  onSubmitSuccess: (response: any, data: any) => void;
}

export default function LeadScreen({
  contactValues,
  onSubmitSuccess,
}: LeadScreenProps) {
  const RECAPTCHA_SITE_KEY = getProductV3CaptchaKey();

  const [dataForm] = useSessionStorage(DATA_FORM);
  const [leadData] = useSessionStorage<LeadData>(DATA_LEAD);

  const router = useRouter();
  const routerQuery = useRouterQuery();
  const { currentStep, productFlow } = useProductFlow();
  const context = useCart();

  const { productTheme, area, setLoading, productBusiness, productCode } =
    context;
  const productHavePolitcsAndTerms =
    productTheme?.data?.isTermsAndPoliticsAvailable;

  const showBirthDateInput = currentStep.props?.showBirthDateInput;
  const isTrialFlow =
    productFlow?.id == 'trial' || productFlow?.id == 'trial-already-used';
  const showDocumentInput = currentStep.props?.showDocumentInput || isTrialFlow;

  const termsUrl =
    currentStep.props?.termsUrl ||
    productTheme?.data?.links[context?.environment]?.terms;

  const [errorMessage, setErrorMessage] = useState({ title: '', message: '' });
  const [politicsChecked, setPoliticsChecked] = useState(false);
  const [startPulseAnimation, setStartPulseAnimation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [shouldShowResponsibleInputs, setShouldShowResponsibleInputs] =
    useState(false);

  const [, setDefaultSelectedPlan] = useSessionStorage('defaultSelectedPlan');
  const captchaRef = useRef<Recaptcha>();

  const defaultCountryLangs = {
    'pt-BR': 'BR',
    'pt-MZ': 'MZ',
  };

  const showResponsibleInputs =
    currentStep.props?.showResponsibleInputs && shouldShowResponsibleInputs;

  const showTermsUrl = !currentStep.props?.hideTermsUrl;

  const validationSchema = useLeadValidationSchema({
    showDocumentInput,
    showBirthDateInput,
    showResponsibleInputs,
    productCode,
  });
  const formik = useFormik({
    initialValues: {
      email: contactValues?.email || dataForm?.email || '',
      name: contactValues?.name || dataForm?.name || '',
      phone: contactValues?.phone || dataForm?.phone || '',
      ...(showBirthDateInput && {
        birthDate: leadData?.birthDate ? unformatDate(leadData.birthDate) : '',
      }),
      ...(showDocumentInput && { cpf: leadData?.cpf || '' }),
      responsibleName: leadData?.responsible?.name || '',
      responsibleDocument: leadData?.responsible?.cpf || '',
    },
    validationSchema,
    onSubmit: () => verifyCaptcha(),
  });
  const birthDateValue = formik.values.birthDate;

  useEffect(() => {
    if (birthDateValue && birthDateValue.length === 10) {
      setShouldShowResponsibleInputs(
        !isBirthDateHigherOrEqualThan18Years(birthDateValue)
      );
    }
  }, [birthDateValue]);

  const validateInput = ({ fieldtype, value, lang }) => {
    const validateObj = validate.inputValidate(fieldtype, value, lang);
    return value === '' ? '' : validateObj.errorMessage;
  };

  const handleSubmit = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (disableButton) return null;
    formik.handleSubmit();
  };

  const sendLeadForm = async ({ name, email, phone, cpf, captchaToken }) => {
    Cookies.set('email', email);

    const gclid = getAnalyticsGclid();

    const hasPlanId = !!currentStep.props.plan?.id;

    const saveLeadDataParams = {
      name,
      email,
      phone,
      area,
      source: routerQuery.source as string,
      operation: 'BR',
      seller: routerQuery.seller as string,
      voucher: routerQuery.voucher as string,
      captchaToken,
      analytics: { gclid },
      business: currentStep.props.plan?.business || productBusiness,
      productCode: currentStep.props.plan?.productCode || productCode,
      metadata: {
        GAClientId: context._ga,
        gclid,
        ...getUtmMetadata(routerQuery as Record<string, string>),
      },
      ...(showDocumentInput && {
        cpf: formatValueBeforeSend(cpf),
      }),
      ...(hasPlanId
        ? {
            planId: currentStep.props.plan?.id,
          }
        : {
            plan: currentStep.props.plan?.plan || 'default',
            planType: currentStep.props.plan?.planType,
          }),
    };

    try {
      const saveLeadDataResponse = await createLead(saveLeadDataParams);

      const tagManagerArgs = {
        dataLayer: {
          event: 'generate_lead',
          phone_number: phone,
          email,
        },
      };

      TagManager.dataLayer(tagManagerArgs);

      if (!saveLeadDataResponse.powerHousePlansEnabledToPurchase?.length) {
        setDefaultSelectedPlan(currentStep?.props?.plan || 'default');
      }

      onSubmitSuccess(
        {
          ...saveLeadDataResponse,
          ...(showBirthDateInput && {
            birthDate: getFormatDate(formik.values.birthDate as string),
          }),
          ...(showDocumentInput && {
            cpf: formik.values.cpf,
          }),
          ...(showResponsibleInputs && {
            responsible: {
              name: formik.values.responsibleName,
              cpf: formik.values.responsibleDocument,
            },
          }),
        },
        {
          ...saveLeadDataParams,
          ...(showResponsibleInputs && {
            responsible: {
              name: formik.values.responsibleName,
              document: {
                type: 'cpf',
                value: formik.values.responsibleDocument,
              },
            },
          }),
        }
      );
    } catch (err) {
      setErrorMessage({
        message: getErrorMessage(area, err?.response?.data?.messageCode),
        title: getErrorTitle(area, err?.response?.data?.messageCode),
      });
    } finally {
      if (context.environment?.toUpperCase() !== 'TEST') {
        captchaRef.current?.reset();
      }
      setDisableButton(false);
      setLoading(false);
    }
  };

  const verifyCaptcha = async () => {
    const hasFormikErrors = Object.keys(formik.errors).length !== 0;
    const isTestEnvironment = context?.environment?.toUpperCase() === 'TEST';

    if (!hasFormikErrors && !politicsChecked && productHavePolitcsAndTerms) {
      setErrorMessage({
        title: 'Política de Privacidade',
        message:
          'Para prosseguir, aceite a Política de Privacidade no checkbox acima do botão.',
      });
      setStartPulseAnimation(true);
      return null;
    }
    setDisableButton(true);
    setLoading(true);
    sendDataToAnalytics(
      {
        userEmail: context?.userEmail,
        source: routerQuery.source,
        seller: routerQuery.seller,
        system: context.productBusiness,
        _ga: context?._ga,
      },
      'lead',
      'click'
    );

    if (isTestEnvironment) {
      sendLeadForm({ ...formik.values, captchaToken: CAPTCHA_TOKEN_STAGING });
    } else {
      captchaRef.current?.execute();
    }
  };

  const handleCheckPolitics = (
    event: Event & {
      detail: {
        checked: boolean;
      };
    }
  ) => {
    setPoliticsChecked(event.detail.checked);
  };

  const formatValueOnChange = ({ inputName, inputValue }) => {
    const formatByInput = {
      email: inputValue?.replace(/ /g, '')?.trim()?.toLowerCase(),
      name: inputValue
        ?.replace(/\s{2,}/g, ' ')
        ?.split(' ')
        ?.map((item) => ReturnFirstCapital(item))
        ?.join(' ')
        ?.toString(),
    };

    formik.setFieldValue(inputName, formatByInput[inputName] || inputValue);
  };

  return (
    <Wrapper>
      <TitleBox>
        <DsTypography
          data-cy="@lead/page-title-message"
          dangerouslySetInnerHTML={{
            __html: currentStep.props?.title || productTheme?.text?.lead?.text,
          }}
          component="heading"
          size="medium"
          color="neutral-pure"
          font={context?.product === 'MS' ? 'secondary' : 'primary'}
        />
        <DsTypography
          data-cy="@lead/page-description-message"
          dangerouslySetInnerHTML={{
            __html:
              currentStep.props?.subtitle ||
              productTheme?.text?.lead?.description,
          }}
          component="paragraph"
          size="medium"
          color="neutral-pure"
          font="primary"
        />
      </TitleBox>

      <Steps
        data-cy="@lead/steps-box"
        stepsNumber={productTheme?.data?.stepsNumber || 5}
        currentStep={1}
      />

      {errorMessage?.message && (
        <ErrorBoxContainer>
          <ErrorBox
            errorTitle={errorMessage.title}
            errorMessage={errorMessage.message}
            onClose={() => setErrorMessage({ message: '', title: '' })}
          />
        </ErrorBoxContainer>
      )}

      <Form>
        {showResponsibleInputs && (
          <DsTypography
            data-cy="@lead/page-title-message"
            component="heading"
            size="small"
            color="neutral-pure"
            font={context?.product === 'MS' ? 'secondary' : 'primary'}
          >
            Dados do aluno
          </DsTypography>
        )}

        <DsTextField
          id="name"
          onChange={({ detail }) =>
            detail?.id === 'name' &&
            formatValueOnChange({
              inputName: detail?.id,
              inputValue: detail?.value,
            })
          }
          onBlur={(event) => formik.handleBlur(event)}
          label={productTheme?.text?.lead?.labels?.name}
          placeholder={productTheme?.text?.lead?.placeholder?.name}
          value={formik.values.name}
          helperText={
            errorsMessage(formik, 'name') ||
            validateInput({
              fieldtype: 'NAME',
              value: formik.values.name,
              lang: 'pt-BR',
            })
          }
          name="name"
          type="text"
          data-cy="@lead/fullname-input"
          maxLength={200}
          data-testid="@jest/ds-name-text-field"
        />
        <DsTextField
          onChange={({ detail }) =>
            detail?.id === 'email' &&
            formatValueOnChange({
              inputName: detail?.id,
              inputValue: detail?.value,
            })
          }
          onBlur={(event) =>
            event?.detail?.id === 'email' && formik.handleBlur(event)
          }
          id="email"
          label={productTheme?.text?.lead?.labels?.email}
          placeholder={productTheme?.text?.lead?.placeholder?.email}
          value={formik.values.email}
          helperText={errorsMessage(formik, 'email')}
          name="email"
          type="email"
          data-cy="@lead/email-input"
          maxLength={80}
          data-testid="@jest/ds-email-text-field"
        />
        <PhoneNumberInput
          value={formik.values.phone}
          fieldtype="PHONE"
          onChange={(phoneNumber) =>
            formatValueOnChange({ inputName: 'phone', inputValue: phoneNumber })
          }
          hasError={errorsMessage(formik, 'phone')}
          label={productTheme?.text?.lead?.labels?.phone}
          dataCy="@lead/cellphone-input"
          dataCyErrorMessage="@lead/errorcellphone-message"
          defaultCountry={defaultCountryLangs[productTheme.data.lang] || 'BR'}
          placeholder={
            productTheme?.text?.lead?.placeholder?.phone || 'Ex: 99 99999 9999'
          }
          onBlur={null}
        />

        {showBirthDateInput && (
          <DsTextField
            id="birthDate"
            label="Data de Nascimento"
            type="text"
            helperText={errorsMessage(formik, 'birthDate')}
            data-cy={`@${router.pathname?.slice(1)}/birthDate`}
            onChange={(event) => {
              formik.setFieldValue(
                event.target.id,
                maskJs('99/99/9999', event.target.value)
              );
            }}
            maxLength={10}
            placeholder="Ex: 13/10/2015"
            value={formik.values.birthDate}
            required
          />
        )}

        {showDocumentInput && (
          <DsTextField
            id="cpf"
            placeholder="Ex: 000.000.000-00"
            label="CPF"
            maxLength={14}
            type="tel"
            helperText={
              errorsMessage(formik, 'cpf') ||
              validateInput({
                fieldtype: 'CPF',
                value: formik.values.cpf,
                lang: 'pt-BR',
              })
            }
            data-cy={`@${router.pathname?.slice(1)}/cpf`}
            onChange={({ target: { id, value } }) => {
              formik.setFieldValue(id, maskJs('999.999.999-99', value));
            }}
            value={formik.values.cpf}
            required
          />
        )}

        {showResponsibleInputs && (
          <>
            <DsTypography
              component="heading"
              size="small"
              color="neutral-pure"
              font={context?.product === 'MS' ? 'secondary' : 'primary'}
            >
              Dados do responsável
            </DsTypography>

            <DsTextField
              id="responsibleName"
              onChange={({ detail }) =>
                detail.id === 'responsibleName' &&
                formatValueOnChange({
                  inputName: detail?.id,
                  inputValue: detail?.value,
                })
              }
              onBlur={(event) => formik.handleBlur(event)}
              label="Nome completo do responsável"
              placeholder={productTheme?.text?.lead?.placeholder?.name}
              value={formik.values.responsibleName}
              helperText={
                errorsMessage(formik, 'responsibleName') ||
                validateInput({
                  fieldtype: 'NAME',
                  value: formik.values.responsibleName,
                  lang: 'pt-BR',
                })
              }
              name="responsibleName"
              type="text"
              data-cy="@lead/fullname-input"
              maxLength={200}
              data-testid="@jest/ds-name-text-field"
              required
            />

            <DsTextField
              id="responsibleDocument"
              placeholder="Ex: 000.000.000-00"
              label="CPF do responsável"
              maxLength={14}
              onBlur={(event) => formik.handleBlur(event)}
              type="tel"
              helperText={
                errorsMessage(formik, 'responsibleDocument') ||
                validateInput({
                  fieldtype: 'CPF',
                  value: formik.values.responsibleDocument,
                  lang: 'pt-BR',
                })
              }
              data-cy={`@${router.pathname?.slice(1)}/responsibleDocument`}
              onChange={({ target: { id, value } }) => {
                formik.setFieldValue(id, maskJs('999.999.999-99', value));
              }}
              value={formik.values.responsibleDocument}
              required
            />
          </>
        )}
        {productHavePolitcsAndTerms && (
          <DsCheckbox
            id="checkbox"
            required
            checked={politicsChecked}
            data-testid="@jest/ds-checkbox-input"
            pulse={startPulseAnimation}
            data-cy="@lead/agreewithprivacypolicy-checkbox"
            onChange={handleCheckPolitics}
          >
            <PoliticsAndTerms
              isMz={context?.isMozambiqueTheme}
              termsUrl={termsUrl}
              politicsUrl={
                productTheme?.data?.links[context?.environment]?.politics
              }
              showTerms={showTermsUrl}
            />
          </DsCheckbox>
        )}

        <DsButton
          id="#LeadScreen/submit-button"
          data-testid="@jest/ds-continue-button"
          data-cy="@lead/continue-button"
          disabled={disableButton}
          onClick={handleSubmit}
        >
          {productTheme?.text?.lead?.button}
        </DsButton>
      </Form>
      <SecurityFooter dataCy="lead" />

      <Recaptcha
        size="invisible"
        ref={captchaRef}
        onChange={(captchaToken) => {
          sendLeadForm({ ...formik.values, captchaToken });
        }}
        sitekey={RECAPTCHA_SITE_KEY}
      />
    </Wrapper>
  );
}
