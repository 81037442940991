import { api } from '../config/base-api';

import { Business, PlanType, PriceMode, Document, PaymentMethod } from 'types';

interface ClientData {
  timestamp: string;
  ipv4: string | null;
  ipv6: string | null;
  email: string | null;
  device: {
    type: string;
    browser: {
      name: string;
      version: string;
    };
    os: {
      version: string;
      name: string;
    };
  };
}

export interface GenerateContractParams {
  business: Business;
  planType: PlanType;
  plan: string; // ver viabilitade de ter um type pros planos
  voucher?: string;
  email: string;
  source: string;
  numberInstallments: number;
  accountId: string;
  priceMode: PriceMode;
  /**
   * @description format: 2024-01-23
   */
  classStartDate: string;
  document: {
    type: Document;
    value: string;
  };
  clientData: ClientData;
  name: string;
  paymentMethodType: PaymentMethod;
  responsible?: {
    name: string;
    document: {
      type: Document;
      value: string;
    };
  };
}

export interface GenerateContractResponse {
  contractHtml: string;
}

export async function generateContract(
  code: string,
  data: GenerateContractParams
): Promise<GenerateContractResponse> {
  const response = await api.post(
    `/api/v1/product/${code}/contract/generate`,
    data
  );

  return response.data;
}
