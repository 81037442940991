import { useCallback, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useSessionStorage<T = any>(
  key: string,
  initialValue?: T
): [T, (value: T) => void, () => void] {
  const [state, setState] = useState<T>(() => {
    try {
      if (typeof window === 'undefined' || !window.sessionStorage) {
        return initialValue;
      }

      const storedValue = window.sessionStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        if (typeof window === 'undefined' || !window.sessionStorage) {
          return;
        }

        setState(value);
        window.sessionStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.warn(`Error setting sessionStorage key "${key}":`, error);
      }
    },
    [key]
  );

  const removeValue = useCallback(() => {
    try {
      if (typeof window === 'undefined' || !window.sessionStorage) {
        return;
      }

      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.warn(`Error removing sessionStorage key "${key}":`, error);
    }
  }, [key]);

  return [state, setValue, removeValue];
}
