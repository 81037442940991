import { api } from 'services/repositories/config/base-api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function makePaymentV1(data: any) {
  console.log(
    '[makePaymentV1] - Payment request initiated for user email:',
    data.email
  );

  const response = await api.post('/api/v1/payment', data);

  return response.data;
}
