import { api } from '../../config/base-api';
import { ProductFlowProps } from 'utils/productFlow';

export interface GetProductConfigResponse {
  id: string;
  name: string;
  title: string;
  gtm: string;
  /**
   * @description
   * format wol$dark | wol$light
   */
  designSystemId: string;
  checkoutFlow: {
    flows: ProductFlowProps[];
  };
}

interface GetProductConfigParams {
  code: string;
  planId?: string;
  isServer?: boolean;
}

export async function getProductConfig({
  code,
  planId,
  isServer = false,
}: GetProductConfigParams): Promise<GetProductConfigResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/product/${code}/config`
    : `/api/v1/product/${code}/config`;

  const res = await api.get<GetProductConfigResponse>(requestUrl, {
    params: {
      planId,
    },
  });
  return res.data;
}
