import { useEffect, useState } from 'react';

import {
  aprovaTotal,
  esqElite,
  euMilitar,
  medcof,
  meusucessoMZ,
  newMeusucesso,
  travelMZ,
  newTravel,
  newPowerhouse,
  wiserGv,
  performanc,
  performancTriade,
  vc,
  cclubGravacao,
  chub,
  vcWiserGv,
  numberone,
  wiseup,
  wiseupLive,
  wsp,
  liberteC,
  resolveEnem,
  conquer,
  aTrinca,
  conquerUnna,
} from 'themes';

import { ProductThemeProps } from './useCart';

/**
 * Array usado para identificar o tema do upsell ofertado e usar as informações desse tema
 * Importante prezar pelos temas de upsell como sendo os primeiros do array
 */
export const allAvailableProductThemes = [
  performancTriade,
  cclubGravacao,
  wiseupLive,
  newPowerhouse,
  newTravel,
  newMeusucesso,
  euMilitar,
  vc,
  chub,
  performanc,
  wsp,
  wiseup,
  numberone,
  aprovaTotal,
  esqElite,
  resolveEnem,
  conquer,
  medcof,
  aTrinca,
];

const getThemeProductsByAreaAndLang = ({ product, area, productSlug }) => {
  const themesByProductAndArea = {
    WUP: {
      BR: newTravel,
      MZ: travelMZ,
    },
    MS: {
      BR: newMeusucesso,
      MZ: meusucessoMZ,
    },
    POWERHOUSE: {
      BR: newPowerhouse,
    },
    EM: {
      BR: euMilitar,
    },
    VC: {
      BR: vc,
    },
    CCLUB: {
      BR: cclubGravacao,
    },
    PERFORMANC: {
      BR: performanc,
    },
    WSP: {
      BR: wsp,
    },
    'C-HUB': {
      BR: chub,
    },
    APROVATOTAL: {
      BR: aprovaTotal,
    },
    'APROVATOTAL-ESQUADRAO-ELITE': {
      BR: esqElite,
    },
    LIVE: {
      BR: wiseupLive,
    },
    EXEC: {
      BR: wiseup,
    },
    NUMBERONE: {
      BR: numberone,
    },
    WISERGV: {
      BR: wiserGv,
    },
    TEENS: {
      BR: wiseup,
    },
    'VENDEC-WISERGV': {
      BR: vcWiserGv,
    },
    MEDCOF: {
      BR: medcof,
    },
    'LIBERTE-C': {
      BR: liberteC,
    },
    'ATRINCA-2025': {
      BR: aTrinca,
    },
    'MLS-FORMACAO-MENTORES': {
      BR: aTrinca,
    },
    RESOLVEENEM: {
      BR: resolveEnem,
    },
    ESCOLACONQUER: {
      BR: conquer,
    },
    'CUNNA-EFLM': {
      BR: conquerUnna,
    },
    ATRINCA: {
      BR: aTrinca,
    },
  };

  if (
    themesByProductAndArea[productSlug?.toUpperCase()] &&
    themesByProductAndArea[productSlug?.toUpperCase()][area]
  ) {
    return themesByProductAndArea[productSlug?.toUpperCase()][area];
  }

  if (themesByProductAndArea[product][area]) {
    return themesByProductAndArea[product][area];
  }

  if (themesByProductAndArea[product]?.BR) {
    return themesByProductAndArea[product].BR;
  }

  return newMeusucesso;
};

export function useProductTheme({ product = 'MS', area = 'BR', productSlug }) {
  const [productTheme, setProductTheme] = useState(
    getThemeProductsByAreaAndLang({
      product,
      area,
      productSlug,
    })
  );

  useEffect(() => {
    setProductTheme(
      getThemeProductsByAreaAndLang({
        product,
        area,
        productSlug,
      })
    );
  }, [product, area, productSlug]);

  return productTheme as ProductThemeProps;
}
