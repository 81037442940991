export enum Business {
  WISEUP_ONLINE = 'wiseup-online',
  WISEUP_LIVE = 'wiseup-live',
  WISEUP = 'wiseup',
  MEUSUCESSO = 'meusucesso',
  EUMILITAR = 'eumilitar',
  POWERHOUSE = 'powerhouse',
  VENDE_C = 'vende-c',
  PERFORMAN_C = 'performan-c',
  WISER = 'wiser',
  APROVATOTAL = 'aprovatotal',
  MEDCOF = 'medcof',
  ESCOLACONQUER = 'conquer-edtech',
  CONQUERUNNA = 'conquer-unna',
  ATRINCA = 'atrinca',
}
