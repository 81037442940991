import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useValidations } from 'utils/validationForm';
import { ProductsCodes } from 'themes/generalTypes';

// date: 2024-01-01
export const unformatDate = (date: string) => {
  const dateSplitted = date.split('-').reverse();
  const dateFormatted = dateSplitted.join('/');

  return dateFormatted;
};

/**
 * @param birthDate - The birth date in the format DD/MM/YYYY
 * @returns true if the birth date is higher or equal than 18 years, false otherwise
 */
export const isBirthDateHigherOrEqualThan18Years = (birthDate: string) => {
  const currentDate = new Date();

  const splitDate = birthDate?.split('/').map((item) => Number(item));
  const [day, month, year] = splitDate;

  const birthDateFormatted = new Date(year, month, day);

  const diffYears = dayjs(currentDate).diff(birthDateFormatted, 'years');

  return diffYears >= 18;
};

export function getFormatDate(date: string) {
  const formattedDate = date.split('/').reverse().join('-');
  return formattedDate;
}

export const useLeadValidationSchema = ({
  showDocumentInput,
  showBirthDateInput,
  showResponsibleInputs,
  productCode,
}: {
  showDocumentInput?: boolean;
  showBirthDateInput?: boolean;
  showResponsibleInputs?: boolean;
  productCode?: string;
}) => {
  const validations = useValidations();

  const baseSchema = {
    email: Yup.string()
      .email('Insira um email válido.')
      .required('Insira um email válido.')
      .test(
        'validate email',
        'Insira um email válido.',
        (value) =>
          !/([àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ])+/.test(
            value as string
          )
      ),
    name: validations.name,
    phone: validations.phone,
  };

  if (showDocumentInput) {
    baseSchema['cpf'] = validations.cpf;
  }

  if (showBirthDateInput) {
    baseSchema['birthDate'] = Yup.string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        'Insira uma data válida no formato DD/MM/YYYY.'
      )
      .required('Insira uma data válida.')
      .test(
        'birthDate',
        `Aluno maior que 16 anos. O curso Teens é voltado para alunos até 16 anos. Solicite ao comercial o curso correspondente à faixa etária informada.`,
        (value) => {
          if (productCode?.toLowerCase() !== ProductsCodes.TEENS || !value) {
            return true;
          }

          const [day, month, year] = value.split('/');
          const birthDate = dayjs(`${year}-${month}-${day}`);
          const sixteenYearsAgo = dayjs().subtract(16, 'year');

          return birthDate.isAfter(sixteenYearsAgo);
        }
      )
      .test(
        'birthDate',
        `Aluno menor que 11 anos. O curso Teens é voltado para alunos a partir de 11 anos. Solicite ao comercial o curso correspondente à faixa etária informada`,
        (value) => {
          if (productCode?.toLowerCase() !== ProductsCodes.TEENS || !value) {
            return true;
          }

          const [day, month, year] = value.split('/');
          const birthDate = dayjs(`${year}-${month}-${day}`);
          const elevenYearsAgo = dayjs().subtract(11, 'year');

          return birthDate.isBefore(elevenYearsAgo);
        }
      );
  }

  if (showResponsibleInputs) {
    baseSchema['responsibleName'] = Yup.string().required(
      'Preencha com nome e sobrenome.'
    );
    baseSchema['responsibleDocument'] = Yup.string()
      .required('Revise o CPF.')
      .test(
        'cpf-responsibleDocument-check',
        'O CPF do responsável não pode ser o mesmo CPF do aluno',
        function (value) {
          const { cpf } = this.parent;
          return value !== cpf;
        }
      );
  }

  return Yup.object().shape(baseSchema);
};
