export function getProductV3CaptchaKey(): string {
  if (typeof window === 'undefined') {
    return;
  }

  if (
    window.location.host.includes('aprovatotal') ||
    window.location.host.includes('resolveenem')
  ) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_APROVATOTAL;
  }

  if (window.location.host.includes('meusucesso')) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_MS;
  }

  if (window.location.host.includes('medcof')) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_MEDCOF;
  }

  if (window.location.host.includes('eumilitar')) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_EUMILITAR;
  }

  if (window.location.host.includes('escolaconquer')) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_CONQUERESCOLA;
  }

  if (window.location.host.includes('atrin.ca')) {
    return process.env.RECAPTCHA_V3_KEY_FRONT_ATRINCA;
  }

  return process.env.RECAPTCHA_V3_KEY_FRONT;
}

export function getProductV2CaptchaKey(): string {
  if (typeof window === 'undefined') {
    return;
  }

  if (
    window.location.host.includes('aprovatotal') ||
    window.location.host.includes('resolveenem')
  ) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_APROVATOTAL;
  }

  if (window.location.host.includes('meusucesso')) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_MS;
  }

  if (window.location.host.includes('medcof')) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_MEDCOF;
  }

  if (window.location.host.includes('eumilitar')) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_EUMILITAR;
  }

  if (window.location.host.includes('escolaconquer')) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_CONQUERESCOLA;
  }

  if (window.location.host.includes('atrin.ca')) {
    return process.env.RECAPTCHA_V2_KEY_FRONT_ATRINCA;
  }

  return process.env.RECAPTCHA_V2_KEY_FRONT;
}
